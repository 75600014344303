import React, { Component } from 'react';
import { LangConsumer } from 'utils/LangProvider';
import { connect } from 'react-redux';
import { Row, Col, Button, Checkbox, message, Form, Input, Alert, Modal, Radio } from 'antd';
import { LabelInput, LabelSelect, LabelSwitch } from 'libs';
import {
    getStorageData, updateFTP, updateSFTP, updateRSP, updateNAS, updateStoragePoint, updateTrafficRecord, saveStorageData,
    getDefaultStorageData, getRemoteStoreList, onFtpTest, updateStorageMode, updateStorageGroup, getDefaultStorageGroup, getDefaultTrafficRecord
} from '../../../store/views/Playback/action';
import { copy, clone } from '../../../common';
import Ability from '../../../ability';
import { lengthB } from 'utils/checkbit';
import { bindLifecycle } from 'utils/bindLifecycle';
import FTPStorageNameTable from '../../ImagePlayback/storage/FTPStorageNameTable';
import webApp from '../../../webapp';

const { TextArea } = Input;

const mapStateToProps = (state) => {
    let { Playback, publicState, webCaps } = state;
    return { Playback, publicState, webCaps };
};

const mapDispatchToProps = dispatch => {
    return {
        getStorageData: (data) => { return dispatch(getStorageData(data)); },
        updateFTP: (data) => { dispatch(updateFTP(data)); },
        updateSFTP: (data) => { dispatch(updateSFTP(data)); },
        updateRSP: (data) => { dispatch(updateRSP(data)); },
        updateNAS: (data) => { dispatch(updateNAS(data)); },
        updateStorageMode: (data) => { dispatch(updateStorageMode(data)); },
        updateStoragePoint: (data) => { dispatch(updateStoragePoint(data)); },
        saveStorageData: (data) => { return dispatch(saveStorageData(data)); },
        getDefaultStorageData: (data) => { return dispatch(getDefaultStorageData(data)); },
        getRemoteStoreList: (data) => { dispatch(getRemoteStoreList(data)); },
        onFtpTest: (data) => { dispatch(onFtpTest(data)); },
        getDefaultStorageGroup: (data) => { dispatch(getDefaultStorageGroup(data)); },
        updateStorageGroup: (data) => { dispatch(updateStorageGroup(data)); },
        updateTrafficRecord: (data) => { dispatch(updateTrafficRecord(data)); },
        getDefaultTrafficRecord: (data) => { dispatch(getDefaultTrafficRecord(data)); }
    };
};

export class StorageComponent extends Component {
    /**用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = 'Storage';
        let { $t } = props;
        this.state = {
            storageDir: 'C:\\PicDownload',
            checkedPlainOptions: [],
            storagePointList: webApp.IsLocalStore ? [
                {
                    text: 'sys.LocalStorage',
                    value: 'Local'
                },
                {
                    text: 'com.NetworkStorage',
                    value: 'networkStorage'
                }
            ] : [{
                text: 'com.NetworkStorage',
                value: 'networkStorage'
            }],
            storagePointVal: webApp.IsLocalStore ? 'Local' : 'networkStorage', // 区分是本地存储还是网络存储
            directoryStructureList: [
                {
                    text: 'sto.SelectDirectoryLevel1',
                    value: 1
                }, {
                    text: 'sto.SelectDirectoryLevel2',
                    value: 2
                }, {
                    text: 'sto.SelectDirectoryLevel3',
                    value: 3
                }
            ],
            directoryLevelList1: [
                {
                    text: 'com.DeviceName',
                    value: '%D'
                }, {
                    text: 'com.DeviceIP',
                    value: '%A'
                }, {
                    text: 'com.Custom',
                    value: ''
                }
            ],
            directoryLevelList2: [
                {
                    text: 'com.FileType',
                    value: '%T'
                }, {
                    text: 'com.ChannelNo',
                    value: '%c'
                }, {
                    text: 'com.Date',
                    value: '%y-%M-%d'
                }, {
                    text: 'sto.FileTypeAndChanelNo',
                    value: '%T_%c'
                }, {
                    text: 'com.Custom',
                    value: ''
                }
            ],
            directoryStructureVal: 1, // 区分使用第几级目录
            directoryLevelVal1: '',//一级目录选中的值
            directoryLevelVal2: '',//二级目录选中的值
            directoryLevelVal3: '',//三级目录选中的值
            directoryLevelCustom1: '',//一级目录自定义的值
            directoryLevelCustom2: '',//二级目录自定义的值
            directoryLevelCustom3: '',//三级目录自定义的值
            pictureNameCustom: '',//图片名称自定义输入框显示的值
            globalPicName: '',//图片存储路径最后一个/后面的名字
            globalRecordName: '',//录像存储路径最后一个/后面的名字
            dialogPicName: '',//弹出框表格下方显示的拼接之后图片名
            defaultCustomName: 'Custom',
            ftpName: 'FTP-FTP1',
            sftpName: 'SFTP-SFTP1',
            ftpStorage: {},
            sftpStorage: {},
            aPicNameShow: [],//图片名称设置弹出框显示使用的数据源，不包含一二三级目录名
            aPicNameConfig: [],//图片名称设置弹出框下发配置使用的数据源，不包含一二三级目录名
            sDateTimeKey: '%y%M%d%h%m%s',//日期关键字
            aDateKeyList: ['%y', '%M', '%d', '%h', '%m', '%s', '%C'],
            aDateList: [{
                Enable: true,
                Disabled: true,//禁止修改勾选框
                Key: '%y%M%d%h%m%s',
                IsGroup: true,
                InnerIndex: 0,
                MaxInnerIndex: 1,
                PictureNameContent: $t('sys.DateAndTime'),//日期时间
                SpaceMark: '_'
            }, {
                Enable: false,
                Key: '%C',
                IsGroup: true,
                InnerIndex: 1,
                MaxInnerIndex: 1,
                PictureNameContent: $t('com.Ms'),//毫秒
                SpaceMark: '_'
            }],
            aPicNameTemplate: [{
                Enable: false,
                Key: '%D',
                hideIcon: true, //隐藏图标
                PictureNameContent: $t('com.DeviceName'),//设备名称
                SpaceMark: '_'
            }, {
                Enable: false,
                Key: '%A',
                hideIcon: true, //隐藏图标
                PictureNameContent: $t('com.IPAddress'),//IP地址
                SpaceMark: '_'
            }, {
                Enable: false,
                Key: '%c',
                hideIcon: true, //隐藏图标
                PictureNameContent: $t('com.ChannelNo'),//通道号
                SpaceMark: '_'
            }],
            visible: false,//图片名称设置，弹出框显示或隐藏的控制
            selectedRowKeys: [],//图片名称设置弹出框中的表格勾选中的行列表
            NetworkStorageList: [
                {
                    text: 'FTP',
                    value: 'FTP'
                }
            ],
            NetworkStorageValue: 'FTP', // 区分是FTP还是NAS
            sdEnable: false,
            ftpOrSftp: 'SFTP',
            storageMode: [
                {
                    text: 'FTP',
                    value: 'FTP'
                }
            ],
            protocolTypeList: [],
            isShowProtocolTypeSel: false,
            formItemLayout: {
                labelCol: {
                    xs: { span: 9 },
                    sm: { span: 9 },
                },
                wrapperCol: {
                    xs: { span: 14 },
                    sm: { span: 14 },
                },
                labelAlign: 'left',
                colon: true,
                hideRequiredMark: true
            },
            protocolType: 'NFS',
            serverIp: '172.2.2.2',
            port: 2330,
            remoteStoreList: [],
            emergency: false,
            supportFtpTest: false,
            modalvisible: false,
            plainOptions: ['Timing', 'MotionDetect', 'Alarm'], // 定时、动检、报警 --修改为 普通，事件，报警
            currentMode: 'Local', //当前所在的存储模式：Local、FTP、NAS
            isShowWarning: false,
            isShowOverWrite: false, // 是否展示满覆盖提示
            oldShowOverWrite: false,
            showProtocolType: false,
            showFTPTip: false,
            nameFormatHelpVisible: false //控制显示命名格式帮助弹框
        };
    }

    componentWillMount () {
    }
    /**此时获取异步数据 */
    async componentDidMount () {
        let { getRemoteStoreList, Playback: { SFTP, Remote, storagePoint, NAS }, $t, webCaps } = this.props;
        //判断是否支持NFS,ISCSI,SMB
        let protocolValue = webCaps['NAS_Protocol_Mask'];
        if (protocolValue) {
            protocolValue = protocolValue.toString(2);
        } else {
            protocolValue = ('').toString(2);
        }
        let protocolTypeList = [];
        if (protocolValue & 4) {
            protocolTypeList.push({
                text: 'NFS',
                value: 'NFS'
            });
        }
        if (protocolValue & 2) {
            protocolTypeList.push({
                text: 'ISCSI',
                value: 'ISCSI'
            });
        }
        if (protocolValue & 1) {
            if (webCaps.is_show_SMB) {
                this.setState({
                    isShowProtocolTypeSel: true
                });
            }
            protocolTypeList.push({
                text: 'SMB',
                value: 'SMB'
            });
        }
        this.setState({
            protocolTypeList: protocolTypeList
        });
        await getRemoteStoreList();
        await Ability.is('RemoteStoreList').then(json => {
            this.setState({
                remoteStoreList: json.RemoteStoreList || []
            });

            if (json.RemoteStoreList && json.RemoteStoreList.indexOf('SFTP') !== -1) {
                this.state.storageMode.unshift({
                    text: ($t) => 'SFTP' + $t('com.Recommend'),
                    value: 'SFTP'
                });
                this.setState({
                    storageMode: this.state.storageMode
                });
            }
        });
        await Ability.is('FtpTest').then((json) => {
            this.setState({
                supportFtpTest: json.Support
            });
        });

        this.getConfig();
    }

    componentWillReceiveProps (props) {
        let { Playback: { SFTP, Remote, storagePoint, NAS, ftpMode }, webCaps } = props;

        // if (SFTP && this.state.storageMode.length === 1) {
        // }
        if (webCaps.show_NAS_config && NAS && this.state.NetworkStorageList.length === 1) {
            this.state.NetworkStorageList.push({
                text: 'NAS',
                value: 'NAS'
            });
            this.setState({
                NetworkStorageList: this.state.NetworkStorageList
            });
        }
        if (storagePoint && (storagePoint.Local.length || storagePoint.FTP.length || storagePoint.NAS.length)) {
            this.updateEventType(props);
        }
    }

    componentDidActivate () {
        this.getConfig();
    }

    changeState = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    changeFTPOrSFTP = ({ ftpMode, nasMode } = {}) => {
        this.setState({
            ftpOrSftp: ftpMode,
            protocolType: nasMode
        }, this.updateEmergencyState);
    }
    updateEmergencyState = () => {
        let { Playback, Playback: { RSP } } = this.props;
        let { ftpOrSftp } = this.state;
        let ftpConfig = Playback[ftpOrSftp];
        let emergency = false; // 是否紧急存储到本地
        if (RSP && RSP[0]) {
            emergency = ftpConfig && ftpConfig.Enable && (
                RSP[0].TimingRecord.LocalForEmergency ||
                RSP[0].VideoDetectRecord.LocalForEmergency ||
                RSP[0].AlarmRecord.LocalForEmergency ||
                false);
        } else {
            emergency = false;
        }
        this.setState({ emergency });
    }
    getConfig = (obj = {}) => {
        let { getStorageData, webCaps } = this.props;
        if (obj) {
            obj.cb = this.changeFTPOrSFTP;
        }
        if (webCaps.is_show_newIntelli) { //球机交通类项目
            obj.addConf = true; //传递到action中，需要在原有获取的配置中添加MultiSceneRecord配置
        }
        getStorageData(obj).then((data) => {
            let { storagePointVal } = this.state;
            let { Playback: { currentImageMode } } = this.props;
            if (storagePointVal === 'networkStorage' && currentImageMode !== 'Local') {
                this.setState({ isShowWarning: true });
            } else {
                this.setState({ isShowWarning: false });
            }
            this.setState({ oldShowOverWrite: data[3][0].OverWrite, isShowOverWrite: false });
            //找出Storage
            if (webCaps.is_show_FtpPathSimplified) {
                this.findStorageAndReadDir();
            }
        });
    }
    findStorageAndReadDir = () => {
        let { sftpName, ftpName } = this.state;
        let { Playback: { FTP, SFTP, StorageGroup } } = this.props;
        for (let i = 0; i < StorageGroup.length; i++) {
            let item = StorageGroup[i];
            if (item.Name === sftpName) {
                this.setState({
                    sftpStorage: item
                });
            }
            if (item.Name === ftpName) {
                this.setState({
                    ftpStorage: item
                });
            }
        }
        //读取路线存储路径的最后一个/后面的名字
        let { ftpOrSftp, ftpStorage, sftpStorage, remoteStoreList } = this.state;
        let ruleTemp = '';
        if (remoteStoreList.indexOf('SFTP') !== -1 && ftpOrSftp === 'SFTP') {
            ruleTemp = sftpStorage.RecordPathRule;
        } else if (ftpOrSftp === 'FTP') {
            ruleTemp = ftpStorage.RecordPathRule;
        }
        //取得最后一个/之后的名称
        if (ruleTemp.indexOf('/') > -1) {
            let tempList = ruleTemp.split('/');
            ruleTemp = tempList[tempList.length - 1];
        }
        this.setState({
            globalRecordName: ruleTemp
        });
        //TODO:调试代码
        //this.readDirectoryLevel('192.168.1.2', '%y-%M-%d/%T_%c/%y%M%d%h%m%s[%E][%O@%S][%R].jpg');
        if (remoteStoreList.indexOf('SFTP') !== -1 && ftpOrSftp === 'SFTP') {
            this.readDirectoryLevel(SFTP.SubDirectory, sftpStorage.PicturePathRule);
        } else {
            this.readDirectoryLevel(FTP.SubDirectory, ftpStorage.PicturePathRule);
        }
    }
    /**
     * 弹出框表格下方显示拼接之后的图片名
     */
    showPictureName = () => {
        let aList = JSON.parse(JSON.stringify(this.state.aPicNameShow));
        let oPicName = this.translatePictureName(aList);
        this.setState({
            dialogPicName: oPicName.ShowName
        });
    }
    onChangeDirectoryStructure = (value) => {
        this.setState({
            directoryStructureVal: value
        });
    }
    onChangeDirectoryLevel1 = (value) => {
        this.setState({
            directoryLevelVal1: value
        });
    }
    onChangeDirectoryLevel2 = (value) => {
        this.setState({
            directoryLevelVal2: value
        });
    }
    onChangeDirectoryLevel3 = (value) => {
        this.setState({
            directoryLevelVal3: value
        });
    }
    onChangeCustom1 = (obj) => {
        this.setState({
            directoryLevelCustom1: obj.currentTarget.value
        });
    }
    onChangeCustom2 = (obj) => {
        this.setState({
            directoryLevelCustom2: obj.currentTarget.value
        });
    }
    onChangeCustom3 = (obj) => {
        this.setState({
            directoryLevelCustom3: obj.currentTarget.value
        });
    }
    onSetPictureNameCustom = () => {
        let { aPicNameConfig } = this.state;
        let list = JSON.parse(JSON.stringify(aPicNameConfig));
        let selectedList = [];//勾选状态列表
        for (let i = 0; i < list.length; i++) {
            if (list[i].Enable) {
                selectedList.push(i);
            }
        }
        this.setState({
            visible: true,
            aPicNameShow: list,
            selectedRowKeys: selectedList
        });
        //表格下方显示名称
        this.showPictureName();
    }
    onSelectChange = (indexList, objList) => {
        var listTemp = this.state.aPicNameShow;
        //先将所有项置为未选中
        for (let j = 0; j < listTemp.length; j++) {
            listTemp[j].Enable = false;
        }
        //然后修改勾选的项
        for (let i = 0; i < indexList.length; i++) {
            listTemp[indexList[i]].Enable = true;
        }
        this.setState({
            aPicNameShow: listTemp,
            selectedRowKeys: indexList
        });
        //弹出框下方拼接名称重新计算
        this.showPictureName();
    }
    onSpaceMarkChange = (index, spaceMark) => {
        let list = this.state.aPicNameShow;
        list[index].SpaceMark = spaceMark;
        this.setState({
            aPicNameShow: list
        });
    }
    onUpAndDown = (index, bUp) => {
        let aPicNameShow = this.state.aPicNameShow;
        let data = aPicNameShow[index];
        if (index === 0 && bUp) {
            return;
        }
        if (index === aPicNameShow.length - 1 && !bUp) {
            return;
        }
        if (bUp) {
            //年月日时分秒是一组，同时移动
            if (data.IsGroup) {
                //序号从0到5，如果分组内的第一个元素是在第一行，不移动
                let firstIndex = index - data.InnerIndex;
                if (firstIndex === 0) {
                    return;
                }
                //找出分组第一个元素在第几行，然后把分组内所有元素逐个移动
                for (let i = firstIndex - 1; i < firstIndex + data.MaxInnerIndex; i++) {
                    //沉底法交换位置
                    var obj = aPicNameShow.splice(i + 1, 1)[0];
                    aPicNameShow.splice(i, 0, obj);
                }
            } else {
                //不是年月日时分秒，要判断上一个是不是年月日时分秒，是的话整体交换位置，否则直接交换位置
                if (aPicNameShow[index - 1].IsGroup) {
                    var minIndex = index - aPicNameShow[index - 1].MaxInnerIndex;
                    for (var i = index; i >= minIndex; i--) {
                        //冒泡法交换位置
                        let obj = aPicNameShow.splice(i, 1)[0];
                        aPicNameShow.splice(i - 1, 0, obj);
                    }
                } else {
                    let obj = aPicNameShow.splice(index, 1)[0];
                    aPicNameShow.splice(index - 1, 0, obj);
                }
            }
        } else {
            //年月日时分秒是一组，同时移动
            if (data.IsGroup) {
                //序号从0到5，如果分组内的第一个元素是在第一行，不移动
                var firstIndex = index - data.InnerIndex;
                if (firstIndex + data.MaxInnerIndex === aPicNameShow.length - 1) {
                    return;
                }
                //找出分组第一个元素在第几行，然后把分组内所有元素逐个移动
                for (let i = firstIndex + data.MaxInnerIndex + 1; i > firstIndex; i--) {
                    //冒泡法交换位置
                    let obj = aPicNameShow.splice(i, 1)[0];
                    aPicNameShow.splice(i - 1, 0, obj);
                }
            } else {
                //不是年月日时分秒，要判断下一个是不是年月日时分秒，是的话整体交换位置，否则直接交换位置
                if (aPicNameShow[index + 1].IsGroup) {
                    var maxIndex = index + aPicNameShow[index + 1].MaxInnerIndex;
                    for (let i = index; i <= maxIndex; i++) {
                        //沉底法交换位置
                        let obj = aPicNameShow.splice(i + 1, 1)[0];
                        aPicNameShow.splice(i, 0, obj);
                    }
                } else {
                    let obj = aPicNameShow.splice(index + 1, 1)[0];
                    aPicNameShow.splice(index, 0, obj);
                }
            }
        }
        this.setState({
            aPicNameShow: aPicNameShow
        });
        this.showPictureName();
        //上下移动之后要重置表格勾选框状态
        let list = JSON.parse(JSON.stringify(aPicNameShow));
        let selectedList = [];//勾选状态列表
        for (let i = 0; i < list.length; i++) {
            if (list[i].Enable) {
                selectedList.push(i);
            }
        }
        this.setState({
            selectedRowKeys: selectedList
        });
    }
    onHandleOk = () => {
        let { $t } = this.props;
        let { aPicNameShow } = this.state;
        //自定义字段，如果是空，则取消选中状态，显示默认的“自定义”
        for (var j = 0; j < aPicNameShow.length; j++) {
            var oNameEle = aPicNameShow[j];
            if (oNameEle.PictureNameContent === '') {
                oNameEle.Enable = false;
                oNameEle.PictureNameContent = $t('com.Custom');
                break;
            }
        }
        var oPicName = this.translatePictureName(aPicNameShow);
        this.setState({
            visible: false,
            globalPicName: oPicName.OriginalName,
            aPicNameConfig: JSON.parse(JSON.stringify(aPicNameShow)),
            pictureNameCustom: oPicName.ShowName
        });
    }
    onHandleCancel = () => {
        this.setState({
            visible: false
        });
    }
    onChangePoint = (value) => {
        let { Playback: { storagePoint, currentImageMode }, updateStoragePoint } = this.props;
        this.setState({
            storagePointVal: value,
            currentMode: value
        });

        if (value === 'networkStorage' && currentImageMode !== 'Local') {
            this.setState({ isShowWarning: true });
        } else {
            this.setState({ isShowWarning: false });
        }

        let tmpVal = 'Local';
        if (value === 'networkStorage') {
            tmpVal = 'FTP';
            this.setState({ currentMode: 'FTP' });
        }

        let checkedPlainOptions = [];
        //先清空一个数组
        let storagePointTmp = copy(storagePoint);
        let checkArr = [false, false, false];
        for (let key in storagePointTmp) {
            storagePointTmp[key] = copy(checkArr);
        }

        // 再给清空的数组重新赋值
        for (let key in storagePoint) {
            let arr = storagePoint[key];
            if (arr.includes(true)) {
                arr = copy(arr);
                storagePointTmp[tmpVal] = arr;
                break;
            }
        }

        updateStoragePoint(storagePointTmp);
        storagePointTmp[tmpVal].forEach((item, index) => {
            if (item) {
                checkedPlainOptions.push(this.state.plainOptions[index]);
            }
        });

        this.setState({ checkedPlainOptions: checkedPlainOptions });
    }

    onChangeNetWorkStorageMode = mode => {
        let { Playback: { storagePoint }, updateStoragePoint } = this.props;

        this.setState({
            NetworkStorageValue: mode,
            currentMode: mode
        });

        let checkedPlainOptions = [];
        //先清空一个数组
        let storagePointTmp = copy(storagePoint);
        let checkArr = [false, false, false];
        for (let key in storagePointTmp) {
            storagePointTmp[key] = copy(checkArr);
        }

        // 再给清空的数组重新赋值
        for (let key in storagePoint) {
            let arr = storagePoint[key];
            if (arr.includes(true)) {
                arr = copy(arr);
                storagePointTmp[mode] = arr;
                break;
            }
        }
        updateStoragePoint(storagePointTmp);
        storagePointTmp[mode].forEach((item, index) => {
            if (item) {
                checkedPlainOptions.push(this.state.plainOptions[index]);
            }
        });

        this.setState({ checkedPlainOptions: checkedPlainOptions });
    }

    // 更新事件类型
    updateEventType (props, point) {
        let { Playback: { storagePoint } } = props;
        let curStoragePoint = this.state.currentMode;
        let checkedPlainOptions = [];
        let storagePointTmp = copy(storagePoint);
        point = point || this.state.currentMode;

        for (let key in storagePointTmp) {
            let arr = storagePointTmp[key];
            if (arr.includes(true)) {
                checkedPlainOptions = [];
                curStoragePoint = key;
                arr.forEach((item, index) => {
                    if (item) {
                        checkedPlainOptions.push(this.state.plainOptions[index]);
                    }
                });
                break;
            } else {
                checkedPlainOptions = [];
                storagePoint[point].forEach((item, index) => {
                    if (item) {
                        checkedPlainOptions.push(this.state.plainOptions[index]);
                    }
                });
            }
        }

        if (curStoragePoint === 'Local') {
            this.setState({ storagePointVal: 'Local' });
        } else {
            this.setState({ storagePointVal: 'networkStorage' });
            if (curStoragePoint === 'NAS') {
                this.setState({ NetworkStorageValue: 'NAS' });
            }
        }

        this.setState({ checkedPlainOptions, currentMode: curStoragePoint});
    }
    onFtpTest = () => {
        let name = 'FTP1';
        let { onFtpTest } = this.props;
        let { ftpOrSftp, remoteStoreList } = this.state;
        if (remoteStoreList.indexOf('SFTP') !== -1 && ftpOrSftp === 'SFTP') {
            name = 'SFTP1';
        }
        let params = {
            name: name
        };
        onFtpTest(params);
    }
    onChangePlainOptions = (checkedList) => {
        let {
            Playback: {
                storagePoint
            },
            updateStoragePoint
        } = this.props;
        let point = this.state.currentMode;
        let checkArr = [false, false, false];

        checkedList.forEach(item => {
            let index = this.state.plainOptions.indexOf(item);
            checkArr[index] = true;
        });
        storagePoint[point] = checkArr;
        for (let key in storagePoint) {
            if (key !== point) {
                storagePoint[key] = [false, false, false];
            }
        }

        updateStoragePoint(storagePoint);
        this.setState({
            checkedPlainOptions: checkedList
        });
    }
    hideModal = (value) => {
        let {
            Playback,
            updateFTP,
            updateSFTP,
            updateRSP,
            Playback: {
                NAS
            },
            updateNAS,
            $t
        } = this.props;
        let { ftpOrSftp } = this.state;
        let tmp = copy(Playback[ftpOrSftp]);
        tmp.Enable = !value;
        if (value) {
            this.setState({ emergency: false });
        }
        if (ftpOrSftp === 'FTP') {
            updateFTP(tmp);
        } else {
            updateSFTP(tmp);
        }
    }

    hideModal2 = (value) => {
        let {
            Playback,
            updateFTP,
            updateSFTP,
            Playback: {
                NAS
            },
            updateNAS,
        } = this.props;
        let { ftpOrSftp } = this.state;
        let tmp = copy(Playback[ftpOrSftp]);

        if (value) {
            NAS = NAS.map((item) => {
                if (item.Protocol !== ftpOrSftp && item['Enable']) {
                    item['Enable'] = false;
                }
                return item;
            });
            updateNAS(NAS);

            if (ftpOrSftp === 'FTP') {
                let sftpTmp = copy(Playback['SFTP']);
                sftpTmp.Enable = false;
                updateSFTP(sftpTmp);
            }
            tmp.Enable = true;
            updateFTP(tmp);
        } else {
            tmp.Enable = false;
            updateFTP(tmp);
        }
    }
    hideModal3 = (value) => {
        let {
            Playback: {
                NAS
            },
            updateNAS,
        } = this.props;
        let currentNASConfig = NAS && NAS.filter((item) => {
            return item.Protocol === this.state.protocolType;
        })[0];
        if (value) {
            currentNASConfig.Enable = false;
            updateNAS(NAS);

        } else {
            currentNASConfig.Enable = true;
            updateNAS(NAS);
        }
    }

    changeFTPConfig = (name, value) => {
        let { Playback, updateFTP, updateSFTP, updateRSP, Playback: {
            NAS
        }, updateNAS, $t } = this.props;
        let { ftpOrSftp } = this.state;

        let config = copy(Playback[ftpOrSftp]);
        let RSP = copy(Playback.RSP);

        if (value && ftpOrSftp === 'FTP' && name === 'Enable') { //ftp开启弹框提示
            this.setState({ showFTPTip: true });
            return;
        }
        config[name] = value;

        if (name === 'emergency') {
            for (let key in RSP) {
                let item = RSP[key];
                item.TimingRecord.LocalForEmergency = value;
                item.VideoDetectRecord.LocalForEmergency = value;
                item.AlarmRecord.LocalForEmergency = value;
            }
            updateRSP(RSP);
            this.setState({ emergency: value });

        } else {

            if (ftpOrSftp === 'FTP') {
                updateFTP(config);
            } else {
                updateSFTP(config);
            }

            if (name === 'Enable') {
                if (!value) {
                    this.setState({ emergency: value });

                } else {
                    NAS = NAS.map((item) => {
                        if (item.Protocol !== ftpOrSftp && item['Enable']) {
                            item['Enable'] = false;
                        }
                        return item;
                    });
                    updateNAS(NAS);
                    if (ftpOrSftp === 'SFTP') {
                        let ftpTmp = copy(Playback['FTP']);
                        ftpTmp.Enable = false;
                        updateFTP(ftpTmp);
                    }
                }
            }
        }
    }
    setFTPEnable = () => {
        let { Playback, updateFTP, updateSFTP, updateRSP, Playback: {
            NAS
        }, updateNAS, $t } = this.props;
        let { ftpOrSftp } = this.state;
        let ftpTmp = copy(Playback['FTP']);
        ftpTmp.Enable = true;
        updateFTP(ftpTmp);
        NAS = NAS.map((item) => {
            if (item.Protocol !== ftpOrSftp && item['Enable']) {
                item['Enable'] = false;
            }
            return item;
        });
        updateNAS(NAS);

        if (ftpOrSftp === 'FTP') {
            let sftpTmp = copy(Playback['SFTP']);
            sftpTmp.Enable = false;
            updateSFTP(sftpTmp);
        }
        this.setState({ showFTPTip: false });
    }
    handleFTPEnable = () => {
        let { Playback, updateFTP, updateSFTP, updateRSP, Playback: {
            NAS
        }, updateNAS, $t } = this.props;
        let { ftpOrSftp } = this.state;
        let config = copy(Playback[ftpOrSftp]);
        config['Enable'] = false;
        updateFTP(config);
        this.setState({ showFTPTip: false });
    }

    onChangeFTPMode = (val) => {
        this.setState({ ftpOrSftp: val }, this.updateEmergencyState);
        let { webCaps } = this.props;
        if (webCaps.is_show_FtpPathSimplified) {
            let { ftpStorage, sftpStorage, remoteStoreList } = this.state;
            let { Playback: { FTP, SFTP } } = this.props;
            if (remoteStoreList.indexOf('SFTP') !== -1 && val === 'SFTP') {
                this.readDirectoryLevel(SFTP.SubDirectory, sftpStorage.PicturePathRule);
            } else {
                this.readDirectoryLevel(FTP.SubDirectory, ftpStorage.PicturePathRule);
            }
        }
    }
    checkSavePath = (rule, value, callback) => {
        let { $t } = this.props;
        /*eslint-disable-next-line*/
        let ruleReg = /[^A-Za-z0-9_\-\/]+/;
        if (ruleReg.test(value)) {
            callback($t('com.PathErrorTip'));
        }
        if (value.length > 64) {
            callback($t('com.MaxLength'));
        }
        callback();
    }
    changeNASConfig = (name, value) => {
        let {
            Playback: {
                NAS,
                SFTP,
                FTP
            },
            $t,
            updateNAS,
            updateSFTP
        } = this.props;

        NAS = NAS.map((item) => {
            if (item.Protocol === this.state.protocolType) {
                item[name] = value;
            }
            return item;
        });

        if (name === 'Enable') {
            let currentNASConfig = NAS && NAS.filter((item) => {
                return item.Protocol === this.state.protocolType;
            })[0];
            if (value) {
                this.setState({ showProtocolType: true });
            }
        }
        updateNAS(NAS);

    }
    setProtocolEnable = () => {
        let {
            Playback: {
                NAS,
                SFTP,
                FTP
            },
            $t,
            updateNAS,
            updateSFTP
        } = this.props;
        this.setState({ showProtocolType: false });
        NAS = NAS.map((item) => {
            if (item.Protocol !== this.state.protocolType && item['Enable']) {
                item['Enable'] = false;
            }
            return item;
        });
        let sftpTmp = copy(SFTP);
        sftpTmp.Enable = false;
        updateSFTP(sftpTmp);
        updateNAS(NAS);
    }
    handleProtocolEnable = () => {
        let {
            Playback: {
                NAS,
                SFTP,
                FTP
            },
            updateNAS,
        } = this.props;
        let currentNASConfig = NAS && NAS.filter((item) => {
            return item.Protocol === this.state.protocolType;
        })[0];
        currentNASConfig.Enable = false;
        this.setState({ showProtocolType: false });
        updateNAS(NAS);
    }
    changeNASProtocolType = (val) => {
        // let {
        //     Playback:{
        //         NAS
        //     },
        //     updateNAS
        // } = this.props;
        // let currentNASConfig = NAS && NAS.filter((item) => {
        //     return item.Protocol === val;
        // })[0];
        // NAS && NAS.map((item) => {
        //     if(currentNASConfig['Enable'] ) {
        //         if(item.Protocol !== val && item['Enable']) {
        //             item['Enable'] = false;
        //         }
        //     }
        //     return item;
        // });
        // console.log(NAS);
        // updateNAS(NAS);
        this.setState({ protocolType: val });
    }

    handleSubmit = (validateFields, resetFields, event) => {
        let { storagePointVal, NetworkStorageValue } = this.state;
        let { $t, webCaps,
            Playback: {
                FTP, SFTP, StorageGroup, TrafficRecord
            }
        } = this.props;

        event.preventDefault();
        let errInfo = null;
        validateFields((err, values) => {
            errInfo = err;
            if (!err) {
                console.log('Received values of form: ', values);
                return;
            }
        });
        if (errInfo) {
            //如果图片路径第一级第二级或者第三级为自定义并且为空，不提示“无效的参数”，直接返回
            let { ftpOrSftp, directoryLevelVal1, directoryLevelVal2, directoryLevelVal3, directoryLevelCustom1, directoryLevelCustom2, directoryLevelCustom3 } = this.state;
            if (webCaps.is_show_FtpPathSimplified && storagePointVal === 'networkStorage' && NetworkStorageValue === 'FTP' && (ftpOrSftp === 'FTP' || ftpOrSftp === 'SFTP') && ((directoryLevelVal1 === '' && directoryLevelCustom1 === '') || (directoryLevelVal2 === '' && directoryLevelCustom2 === '') || (directoryLevelVal3 === '' && directoryLevelCustom3 === ''))) {
                return;
            }
            message.warn($t('com.InvalidParam'));
            return;
        }
        //图片路径自定义
        if (webCaps.is_show_FtpPathSimplified) {
            let { ftpOrSftp, directoryStructureVal, directoryLevelVal1, directoryLevelVal2, directoryLevelVal3, directoryLevelCustom1, directoryLevelCustom2, directoryLevelCustom3, globalPicName, globalRecordName } = this.state;
            let PicturePathRuleList = [];//二级与三级目录路径数组
            //取值从二级目录开始，一级目录的值从NAS配置的SubDirectory字段获取
            if (directoryStructureVal === 2 || directoryStructureVal === 3) {
                if (directoryLevelVal2 === '') {
                    //自定义路径，取输入框值
                    PicturePathRuleList.push(directoryLevelCustom2);
                } else {
                    //非自定义路径，取下拉框值
                    PicturePathRuleList.push(directoryLevelVal2);
                }
                if (directoryStructureVal === 3) {
                    if (directoryLevelVal3 === '') {
                        //自定义路径，取输入框值
                        PicturePathRuleList.push(directoryLevelCustom3);
                    } else {
                        //非自定义路径，取下拉框值
                        PicturePathRuleList.push(directoryLevelVal3);
                    }
                }
            }
            let { sftpStorage, ftpStorage, remoteStoreList } = this.state;
            let PicturePathRule = PicturePathRuleList.join('/') + (PicturePathRuleList.length > 0 ? '/' : '') + globalPicName;//二三级路径加图片名称
            let RecordPathRule = PicturePathRuleList.join('/') + (PicturePathRuleList.length > 0 ? '/' : '') + globalRecordName;//二三级路径加录像名称
            if (remoteStoreList.indexOf('SFTP') !== -1 && ftpOrSftp === 'SFTP') {
                sftpStorage.PicturePathRule = PicturePathRule;
                sftpStorage.RecordPathRule = RecordPathRule;
                SFTP.SubDirectory = directoryLevelVal1;//一级目录
                //如果选择了自定义，从输入框取值
                if (SFTP.SubDirectory === '') {
                    SFTP.SubDirectory = directoryLevelCustom1;
                }
                updateSFTP(SFTP);
            } else if (ftpOrSftp === 'FTP') {
                ftpStorage.PicturePathRule = PicturePathRule;
                ftpStorage.RecordPathRule = RecordPathRule;
                FTP.SubDirectory = directoryLevelVal1;//一级目录
                //如果选择了自定义，从输入框取值
                if (FTP.SubDirectory === '') {
                    FTP.SubDirectory = directoryLevelCustom1;
                }
                updateFTP(FTP);
            }
            //找出StorageGroup.FTP.Storage或者StorageGroup.SFTP.Storage
            for (let i = 0; i < StorageGroup.length; i++) {
                let item = StorageGroup[i];
                if (item.Name === this.state.sftpName) {
                    StorageGroup[i] = sftpStorage;
                }
                if (item.Name === this.state.ftpName) {
                    StorageGroup[i] = ftpStorage;
                }
            }
            updateStorageGroup(StorageGroup);
        }
        // 录像及存储路径命名必须以 dav/avi/mp4 结尾
        if (webApp.DeviceType.indexOf('SD') !== -1 && !!webCaps.is_show_newIntelli && storagePointVal === 'networkStorage' && NetworkStorageValue === 'FTP' && TrafficRecord[0].VideoNamingFormat) {
            let temp = TrafficRecord[0].VideoNamingFormat.Format;
            // 报错兼容
            if (typeof(temp) === 'string') {
                let len = temp.length;
                let isDav = (temp.charAt(len - 4) === '.') && (temp.charAt(len - 3) === 'd') && (temp.charAt(len - 2) === 'a') && (temp.charAt(len - 1) === 'v');
                let isAvi = (temp.charAt(len - 4) === '.') && (temp.charAt(len - 3) === 'a') && (temp.charAt(len - 2) === 'v') && (temp.charAt(len - 1) === 'i');
                let isMp4 = (temp.charAt(len - 4) === '.') && (temp.charAt(len - 3) === 'm') && (temp.charAt(len - 2) === 'p') && (temp.charAt(len - 1) === '4');
                if (!(isDav || isAvi || isMp4)) {
                    message.warn($t('com.VideoNamedTip').replace('%s', '.dav/.avi/.mp4'));
                    return;
                }
            }
        }
        this.onSave(resetFields);
    }

    onSave (resetFields) {
        let {
            saveStorageData,
            $t,
            Playback: {
                StorageGroup, sdAuth
            },
        } = this.props;
        let { ftpOrSftp, storagePointVal } = this.state;

        if (sdAuth && storagePointVal === 'Local') {
            message.info($t('sto.SDEncryptRecordTip'));
        }
        saveStorageData({
            success () {
                message.success($t('com.Operateingsuccess'));
            },
            error () {
                message.error($t('com.Operateingfailure'));
            },
            emergencyFlag: this.state.emergency,
            ftpMode: ftpOrSftp
        }).then(() => {
            resetFields && resetFields();
        });

        this.setState({
            oldShowOverWrite: StorageGroup[0].OverWrite,
            isShowOverWrite: false
        });
    }
    onDefault = (obj) => {
        let {
            Playback,
            Playback: {
                NAS,
                storagePoint,
                currentImageMode
            },
            updateStoragePoint,
            $t,
            getDefaultStorageData,
            getDefaultStorageGroup,
            webCaps
        } = this.props;
        let { ftpOrSftp, storagePointVal, plainOptions, currentMode } = this.state;
        let ftpConfig = Playback[ftpOrSftp];
        let currentNASConfig = NAS && NAS.filter((item) => {
            return item.Protocol === this.state.protocolType;
        })[0];
        if (obj) {
            obj.cb = this.changeFTPOrSFTP;
        }
        if (webCaps.is_show_newIntelli) { //球机交通类项目
            obj.addConf = true; //传递到action中，需要在原有获取的配置中添加MultiSceneRecord配置
        }
        this.setState({ isShowOverWrite: false });
        // 如果图片当前为本地存储，无论当前录像为什么存储方式直接全部恢复默认
        if (currentImageMode === 'Local') {
            getDefaultStorageData(obj).then(() => {
                this.setState({ NetworkStorageValue: 'FTP', isShowWarning: false });
                //FTP图片路径自定义，恢复默认，读取最新获取的数据（默认值）
                //找出Storage
                let { webCaps } = this.props;
                if (webCaps.is_show_FtpPathSimplified) {
                    this.findStorageAndReadDir();
                }
            });
        }
        // 如果图片当前模式为网络存储，录像也为网络存储，全部恢复
        if (currentMode !== 'Local' && currentImageMode !== 'Local') {
            getDefaultStorageData(obj).then(() => {
                this.setState({ NetworkStorageValue: 'FTP', isShowWarning: false });
                //FTP图片路径自定义，恢复默认，读取最新获取的数据（默认值）
                //找出Storage
                if (webCaps.is_show_FtpPathSimplified) {
                    this.findStorageAndReadDir();
                }
            });
        }
        // 如果录像为本地存储，图片为网络存储，则不恢复图片的存储点配置，同时。恢复除了图片当前NAS配置外的所有配置
        if (currentMode === 'Local' && currentImageMode !== 'Local') {
            console.log('不恢复默认');
            this.setState({
                storagePointVal: 'Local',
                isShowWarning: false
            });

            message.success($t('com.OperateingSuccessTip'));

            let storagePointTmp = copy(storagePoint);
            let checkArr = [false, false, false];
            for (let key in storagePointTmp) {
                storagePointTmp[key] = copy(checkArr);
            }

            storagePointTmp['Local'] = [true, true, true];
            updateStoragePoint(storagePointTmp);
            getDefaultStorageGroup();
            let checkedPlainOptions = [];
            storagePointTmp['Local'].forEach((item, index) => {
                if (item) {
                    checkedPlainOptions.push(this.state.plainOptions[index]);
                }
            });
            this.setState({ checkedPlainOptions: checkedPlainOptions });
        }
    }

    changeStorageGroup = event => {
        let { Playback: {
            StorageGroup
        }, updateStorageGroup } = this.props;
        let StorageGroupTmp = copy(StorageGroup);
        let { oldShowOverWrite } = this.state;

        if (event.target.value === oldShowOverWrite) {
            this.setState({ isShowOverWrite: false });
        } else {
            this.setState({ isShowOverWrite: true });
        }

        StorageGroupTmp[0].OverWrite = StorageGroupTmp[1].OverWrite = event.target.value;
        updateStorageGroup(StorageGroupTmp);
    }

    readDirectoryLevel = (strFirstLevel, strOtherLevel) => {
        //第一级目录
        if (strFirstLevel.indexOf('%') < 0) {
            //未找到通配符，是用户自定义的字符
            this.setState({
                directoryLevelVal1: '',
                directoryLevelCustom1: strFirstLevel
            });
        } else {
            this.setState({
                directoryLevelVal1: strFirstLevel,
                directoryLevelCustom1: ''
            });
        }
        //解析第二级和第三级目录值
        let dirList = strOtherLevel.split('/');
        this.setState({
            directoryStructureVal: dirList.length > 3 ? 3 : dirList.length//目录结构选了几级
        });
        //解析第二三级目录，老版本设备升级会超过三级目录，甚至到六级，只解析三级
        let iLen = dirList.length - 1;
        if (iLen > 2) {
            iLen = 2;
        }
        for (let i = 0; i < iLen; i++) {
            //取值从二级目录开始，一级目录的值从NAS配置的SubDirectory字段获取
            //如果不包含百分号%，说明不能匹配通配符，是用户自定义的字符
            let dir = dirList[i];
            let valTemp = dir;
            let customTemp = '';
            if (dir.indexOf('%') < 0) {
                //自定义
                valTemp = '';
                customTemp = dir;
            }
            if (i === 0) {
                this.setState({
                    directoryLevelVal2: valTemp,
                    directoryLevelCustom2: customTemp
                });
            } else if (i === 1) {
                this.setState({
                    directoryLevelVal3: valTemp,
                    directoryLevelCustom3: customTemp
                });
            }
        }
        let globalPicNameTemp = dirList[dirList.length - 1];
        this.setState({
            globalPicName: globalPicNameTemp//图片名称，格式:'%y%M%d%h%m%s[%E][%O@%S][%R].jpg'
        });
        let aPicNameShow = this.checkNameEleEnable(globalPicNameTemp);//判断图片名各元素的选中情况，返回值是为了防止state更新未同步，所以不适用state的值
        //根据图片元素勾选情况，将带有百分号的图片名称翻译成文字，组装名称字符串
        let oPicName = this.translatePictureName(aPicNameShow);
        this.setState({
            pictureNameCustom: oPicName.ShowName
        });
    }
    searchCustomStr = (str) => {
        let oNameEle = {
            OtherStr: str,
            SpaceMark: '',//间隔符
            CustomStr: ''
        };
        let index0 = str.indexOf('%');
        if (index0 === 0) {
            return oNameEle;
        } else {
            if (index0 > 0) {
                oNameEle.OtherStr = str.substring(index0);
                str = str.substring(0, index0);
            } else {
                oNameEle.OtherStr = '';
            }
            let aSeparatorList = ['_', '-'];
            let sLastStr = str.substr(str.length - 1, 1);
            //判断最后一个字符是否为分隔符
            if (aSeparatorList.indexOf(sLastStr) > -1) {
                oNameEle.SpaceMark = sLastStr;
                oNameEle.CustomStr = str.substring(0, str.length - 1);
            } else {
                oNameEle.SpaceMark = '';
                oNameEle.CustomStr = str;
            }
        }
        return oNameEle;
    }
    orderDateElement = (aList, iFirstDate, sFirstDateSeperator) => {
        let aDateTemp = JSON.parse(JSON.stringify(this.state.aDateList));//先复制日期元素模板的值
        if (iFirstDate < 0) {
            iFirstDate = aList.length;//日期元素出现的第一个元素序号
        }

        aDateTemp[0].SpaceMark = sFirstDateSeperator;//时间日期的间隔符
        for (let i = aList.length - 1; i >= 0; i--) {
            //如果是分组元素，说明是日期元素，从原数组移除，放入临时数组，多次需求变更之后只对毫秒生效
            if (aList[i].IsGroup) {
                aDateTemp[aList[i].InnerIndex] = aList.splice(i, 1)[0];
            }
        }
        //将日期数组插入原数组
        for (let j = aDateTemp.length - 1; j >= 0; j--) {
            aList.splice(iFirstDate, 0, aDateTemp[j]);
        }
        return aList;
    }
    /**
     * 从名称字符串中读取，判断图片名称中哪些元素被勾选，排序，构造表格的源数据
     * @param sPicName
     */
    checkNameEleEnable = (sPicName) => {
        let { $t } = this.props;
        //图片名称，格式:'%y%M%d%h%m%s[%E][%O@%S][%R].jpg'
        let aPicNameShow = JSON.parse(JSON.stringify(this.state.aPicNameTemplate.concat(this.state.aDateList, [{
            Enable: false,
            Key: '%B',
            hideIcon: true, //隐藏图标
            PictureNameContent: $t('SnapMode'),//抓图类型
            SpaceMark: '_'
        }, {
            Enable: false,
            Key: '',
            hideIcon: true, //隐藏图标
            PictureNameContent: $t('com.Custom'),//自定义字段
            SpaceMark: '_'
        }])));//先复制模板的值
        var aPicNameShowTemp = [];//保存临时结果
        var sPicNameTemp = sPicName;//[之前的字符串
        //如果包含[，解析时忽略[之后的字符
        if (sPicName.indexOf('[') !== -1) {
            sPicNameTemp = sPicName.substr(0, sPicName.indexOf('['));
        }
        var aList = sPicNameTemp.split('%');
        //如果第一个字符不是%，则表示第一个字符肯定是自定义字段，可能还包含后面的字段
        //先截断字符串，把自定义字段取出来
        var customStr = '';
        var SpaceMark = '';
        if (sPicNameTemp.substr(0, 1) !== '%') {
            var oPicStr = this.searchCustomStr(sPicNameTemp);
            customStr = oPicStr.CustomStr;
            sPicNameTemp = oPicStr.OtherStr;
            SpaceMark = oPicStr.SpaceMark;
            aList = sPicNameTemp.split('%');
        }
        var aSeparatorList = ['_', '-'];//分隔符列表
        var iFirstDate = -1;//日期元素出现的第一个元素序号
        var sFirstDateSeperator = '';//时间日期后的间隔符
        //判断时间日期后面是否有间隔符
        var iIndexDateTime = sPicNameTemp.indexOf(this.state.sDateTimeKey);//日期时间完整字符串出现的序号
        if (iIndexDateTime > -1 && sPicNameTemp.length > iIndexDateTime + 1 && aSeparatorList.indexOf(sPicNameTemp.substr(iIndexDateTime + this.state.sDateTimeKey.length, 1)) > -1) {
            sFirstDateSeperator = sPicNameTemp.substr(iIndexDateTime + this.state.sDateTimeKey.length, 1);
        }
        //如果字符串开头是自定义字段，则要优先处理
        if (customStr !== '') {
            for (var k = aPicNameShow.length - 1; k >= 0; k--) {
                var oNameEle = aPicNameShow[k];
                if (oNameEle.Key === '') {
                    oNameEle.Enable = true;//选中
                    oNameEle.SpaceMark = SpaceMark;
                    //自定义字段要修改显示
                    oNameEle.PictureNameContent = customStr;
                    aPicNameShowTemp.push(aPicNameShow.splice(k, 1)[0]);//从数组中删除再保存到结果列表
                    break;
                }
            }
        }
        //勾选使能并排序
        for (var i = 0; i < aList.length; i++) {
            //TODO:多个字符的时候，可能包含自定义字符串（A-Z a-z 0-9），要区别对待.还要考虑间隔符(只能是下划线或者空)
            if (aList[i].length === 1) {
                //没有自定义字符，没有间隔符
                var key = '%' + aList[i];
                //判断是否为日期元素，记录序号
                if (this.state.aDateKeyList.indexOf(key) > -1 && iFirstDate === -1) {
                    iFirstDate = aPicNameShowTemp.length;
                }
                for (var j = aPicNameShow.length - 1; j >= 0; j--) {
                    let oNameEle = aPicNameShow[j];
                    if (oNameEle.Key === key) {
                        oNameEle.Enable = true;//选中
                        oNameEle.SpaceMark = '';
                        aPicNameShowTemp.push(aPicNameShow.splice(j, 1)[0]);//从数组中删除再保存到结果列表
                        break;
                    }
                }
            } else if (aList[i].length === 2) {
                //第二个字符为间隔符或自定义字符
                var str1 = aList[i].substr(0, 1);
                var str2 = aList[i].substr(1, 1);
                let key = '%' + str1;
                //判断是否为日期元素，记录序号
                if (this.state.aDateKeyList.indexOf(key) > -1 && iFirstDate === -1) {
                    iFirstDate = aPicNameShowTemp.length;
                }
                for (let j = aPicNameShow.length - 1; j >= 0; j--) {
                    let oNameEle = aPicNameShow[j];
                    if (oNameEle.Key === key) {
                        oNameEle.Enable = true;//选中
                        if (aSeparatorList.indexOf(str2) >= 0) {
                            oNameEle.SpaceMark = str2;
                        } else {
                            oNameEle.SpaceMark = '';
                        }
                        aPicNameShowTemp.push(aPicNameShow.splice(j, 1)[0]);//从数组中删除再保存到结果列表
                        break;
                    }
                }
                if (aSeparatorList.indexOf(str2) < 0) {
                    let key2 = '';
                    for (let k = aPicNameShow.length - 1; k >= 0; k--) {
                        let oNameEle = aPicNameShow[k];
                        if (oNameEle.Key === key2) {
                            oNameEle.Enable = true;//选中
                            oNameEle.SpaceMark = '';
                            //自定义字段要修改显示
                            oNameEle.PictureNameContent = str2;
                            aPicNameShowTemp.push(aPicNameShow.splice(k, 1)[0]);//从数组中删除再保存到结果列表
                            break;
                        }
                    }
                }
            } else if (aList[i].length >= 3) {
                //两种情况：第二个字符为间隔符，则第三个为自定义字段；第二个为自定义字段，第三个为间隔符
                let str1 = aList[i].substr(0, 1);
                let str2 = aList[i].substr(1, 1);
                let strX = aList[i].substr(aList[i].length - 1, 1);
                let key = '%' + str1;
                //判断是否为日期元素，记录序号
                if (this.state.aDateKeyList.indexOf(key) > -1 && iFirstDate === -1) {
                    iFirstDate = aPicNameShowTemp.length;
                }
                for (let j = aPicNameShow.length - 1; j >= 0; j--) {
                    let oNameEle = aPicNameShow[j];
                    if (oNameEle.Key === key) {
                        oNameEle.Enable = true;//选中
                        if (aSeparatorList.indexOf(str2) >= 0) {
                            oNameEle.SpaceMark = str2;
                        } else {
                            oNameEle.SpaceMark = '';
                        }
                        aPicNameShowTemp.push(aPicNameShow.splice(j, 1)[0]);//从数组中删除再保存到结果列表
                        break;
                    }
                }
                //判断第2个字符是不是分隔符，如果是，则第3个字符开始是另一个字段
                if (aSeparatorList.indexOf(str2) >= 0) {
                    //判断最后一个字符是不是分隔符
                    let key3 = '';
                    for (let k = aPicNameShow.length - 1; k >= 0; k--) {
                        let oNameEle = aPicNameShow[k];
                        if (oNameEle.Key === key3) {
                            oNameEle.Enable = true;//选中
                            if (aSeparatorList.indexOf(strX) >= 0) {
                                oNameEle.SpaceMark = strX;
                            } else {
                                oNameEle.SpaceMark = '';
                            }
                            //自定义字段要修改显示
                            if (aSeparatorList.indexOf(strX) >= 0) {
                                oNameEle.PictureNameContent = aList[i].substring(2, aList[i].length - 1);
                            } else {
                                oNameEle.PictureNameContent = aList[i].substring(2);
                            }
                            aPicNameShowTemp.push(aPicNameShow.splice(k, 1)[0]);//从数组中删除再保存到结果列表
                            break;
                        }
                    }
                } else {
                    //第二个字符不是分隔符
                    //判断最后一个字符是不是分隔符
                    let key3 = '';
                    for (let k = aPicNameShow.length - 1; k >= 0; k--) {
                        let oNameEle = aPicNameShow[k];
                        if (oNameEle.Key === key3) {
                            oNameEle.Enable = true;//选中
                            if (aSeparatorList.indexOf(strX) >= 0) {
                                oNameEle.SpaceMark = strX;
                            } else {
                                oNameEle.SpaceMark = '';
                            }
                            //自定义字段要修改显示
                            if (aSeparatorList.indexOf(strX) >= 0) {
                                oNameEle.PictureNameContent = aList[i].substring(1, aList[i].length - 1);
                            } else {
                                oNameEle.PictureNameContent = aList[i].substring(1);
                            }
                            aPicNameShowTemp.push(aPicNameShow.splice(k, 1)[0]);//从数组中删除再保存到结果列表
                            break;
                        }
                    }
                }
            }
        }
        //排序前先删除剩余的年月日时分秒毫秒元素，因为老版本数据不全，可能导致删除不干净，所以再次删除
        for (let n = aPicNameShow.length - 1; n >= 0; n--) {
            if (this.state.aDateKeyList.indexOf(aPicNameShow[n].Key) > -1 || this.state.sDateTimeKey === aPicNameShow[n].Key) {
                aPicNameShow.splice(n, 1);//从数组中删除再保存到结果列表
            }
        }
        aPicNameShowTemp = this.orderDateElement(aPicNameShowTemp, iFirstDate, sFirstDateSeperator);//再次对日期元素排序
        aPicNameShow = aPicNameShowTemp.concat(aPicNameShow);
        this.setState({
            aPicNameShow: [].concat(aPicNameShow),//将已排序的名称元素和未排序的组合在一起
            aPicNameConfig: JSON.parse(JSON.stringify(aPicNameShow))
        });
        return [].concat(aPicNameShow);
    }
    /**
     * 从弹出框解析数据，将带有百分号的图片名称翻译成文字
     */
    translatePictureName = (aList) => {
        let { $t } = this.props;
        let sResultOriginal = '';
        let sResultShow = '';
        let oLast = null;//被选中的元素列表中的最后一个
        for (let j = 0; j < aList.length; j++) {
            let oNameEle = aList[j];
            //不包含%的是自定义字段，先给Key赋值
            if (oNameEle.Key.indexOf('%') === -1 && oNameEle.PictureNameContent !== '') {
                //如果当前语言不是英文，并且是默认的翻译“自定义字段”，则替换成Custom。因为Key只允许字母，数字
                if (this.props.langlocale.locale !== 'English' && oNameEle.PictureNameContent === $t('com.Custom')) {
                    oNameEle.Key = this.state.defaultCustomName;
                } else {
                    oNameEle.Key = oNameEle.PictureNameContent;
                }
            }
            //拼接名称
            if (oNameEle.Enable && oNameEle.Key !== '' && oNameEle.PictureNameContent !== '') {
                sResultOriginal += oNameEle.Key + oNameEle.SpaceMark;
                sResultShow += oNameEle.PictureNameContent + oNameEle.SpaceMark;
                oLast = oNameEle;
            }
        }
        //如果有后缀字符串，要加上，例如：[%E][%O@%S][%R].jpg
        if (this.state.globalPicName.indexOf('[') !== -1) {
            sResultOriginal += this.state.globalPicName.substr(this.state.globalPicName.indexOf('['));//加上后缀字符串，例如：[%E][%O@%S][%R].jpg
        }
        return {
            OriginalName: sResultOriginal,//用来保存的名字
            ShowName: sResultShow//用来显示的名字
        };
    }
    /**
     * 校验ServerIP的IP，当ServerIP不为空时，需要为IPV4或者IPV6，且不能以127，0或者大于223开头
     * 第三个不能为0也不能大于254
     * @param {object} rule
     * @param {string} value 输入的值
     * @param {function} callback 回调函数
     */
    checkServerIP = (rule, value, callback) => {
        //参考视图库页面代码 主动注册页面 AutoRegister
        let { $t, webCaps } = this.props;
        let ipRegOld = /^[\w-.]+$/;//原有判断逻辑，如果不支持ipv6能力集，则依然使用就的规则判断
        let ipRegNew = /^[\w-.:]+$/;
        let regExceptList = /(^(::)$)|(^(::1)$)|(^(FF))|(^(FE8))|(^(FE9))|(^(FEA))|(^(FEB))|(^(FC))|(^(FD))|(^(FEC))|(^(FED))|(^(FEE))|(^(FEF))/;
        //ipv4或者ipv6
        //TODO:如果支持ipv6
        if (webCaps.is_show_externalIpv6) {
            if (!ipRegNew.test(value) || regExceptList.test(value)) {
                callback($t('com.ServerIpError'));
            }
        } else {
            if (!ipRegOld.test(value)) {
                callback($t('com.EmailiperrorTip'));
            }
        }
        callback();
    }
    /**
     * 监听录像格式的变化
     * @param {Object} e 事件对象
     */
    nameFormatChange = (e) => {
        let { Playback: {
            TrafficRecord
        }, updateTrafficRecord} = this.props;
        let value = e.target.value;
        let reg = /["*:<>?|\\\s]+/;
        // 替换输入非法字符（文件名不能包含 ? * : " < > \ / | ），但 / 需要作为路径保留
        if (reg.test(value)) {
            value = value.replace(reg, '');
        }
        let tmpTrafficRecord = clone(TrafficRecord);
        tmpTrafficRecord[0].VideoNamingFormat.Format = value;
        updateTrafficRecord(tmpTrafficRecord);
    }
    /**
     * 根据不同的类型，返回可预览的字符串
     * @returns {String} returnValue 可预览的字符串
     */
    nameFormatPreview = () => {
        let { Playback: {
            TrafficRecord
        }} = this.props;
        let returnValue = '';
        if (TrafficRecord && TrafficRecord[0] && TrafficRecord[0].VideoNamingFormat) {
            returnValue = this.formatContentChangePreview(TrafficRecord[0].VideoNamingFormat.Format);
        }
        return returnValue;
    }
    /**
     * 将标识符转换为可预览的字符串
     * @param {String} data 标识符数据
     * @returns {String} returnVal 可预览的字符串
     */
    formatContentChangePreview = (data) => {
        let {$t} = this.props;
        let returnVal = '';
        let formatContentLength = data.length;
        let commonparameter = ['%00',
            'PZC2AW01800060',
            '10.61.2.129',
            $t('ivs.SouthToNorth'),
            '2',
            '50',
            '180',
            $t('ivs.RunRedLight'),
            '1',
            $t('EUP5689'),
            $t('ivs.SmallCarColor'),
            $t('Light-duty'),
            $t('com.Yellow'),
            '3',
            '2',
            '10.61.2.128',
            $t('com.BlueColor'),
            '19_45_29_59',
            '1547aref',
            $t('com.Road'),
            $t('com.Companys'),
            $t('ivs.Panorama'),
            'PZC2AW01800060',
            '1',
            '3',
            '30S',
            $t('ivs.TurnLeft'),
            '1',
            '12345678910',
            '1',
            $t('com.Companys'),
            '0001']; // 普通参数
        let num = '';
        for (let i = 0; i < formatContentLength; i++) {
            if ((i + 2) < formatContentLength && data.charAt(i) === '%' && /[0-9]/g.test(data.charAt(i + 1)) && /[0-9]/g.test(data.charAt(i + 2))) {
                if (data.charAt(i + 1) !== 0) {
                    num += data.charAt(i + 1);
                }
                num += data.charAt(i + 2);
                let listnum = {
                    '66':'27',
                    '36':'29',
                    '47':'30',
                    '63':'31'
                };
                num = listnum[num] || num;
                num = num - 0;
                if (num < 32 && num > 0) {
                    returnVal += commonparameter[num];
                } else {
                    returnVal += data.charAt(i);
                    returnVal += data.charAt(i + 1);
                    returnVal += data.charAt(i + 2);
                }
                num = '';
                i += 2;
            } else if ((i + 1) < formatContentLength && data.charAt(i) === '%' && /[a-zA-Z]/g.test(data.charAt(i + 1))) {
                let item = this.timedatavalue(data.charAt(i + 1));
                if (item) {
                    returnVal += item;
                } else {
                    if (data.charAt(i + 1) === 'c') {
                        returnVal += '1';
                    } else {
                        returnVal += data.charAt(i);
                        returnVal += data.charAt(i + 1);
                    }
                }
                i++;
            } else if ((i + 1) < formatContentLength && data.charAt(i) === '%' && data.charAt(i + 1) === '%') {
                returnVal += data.charAt(i);
            } else {
                returnVal += data.charAt(i);
            }
        }
        return returnVal;
    }
    /**
     * 时间标识符转换为可预览的字符
     * @param {String} item 时间标识符
     * @returns {String} str 可预览的字符
     */
    timedatavalue = (item) => {
        let timedataparameter = ['2013', '01', '06', '15', '27', '30', '110']; // 时间/日期参数
        let str = 0;
        switch (item) {
        case 'y':
            str = timedataparameter[0];
            break;
        case 'M':
            str = timedataparameter[1];
            break;
        case 'd':
            str = timedataparameter[2];
            break;
        case 'h':
            str = timedataparameter[3];
            break;
        case 'm':
            str = timedataparameter[4];
            break;
        case 's':
            str = timedataparameter[5];
            break;
        case 'S':
            str = timedataparameter[6];
            break;
        default:
            break;
        }
        return str;
    }
    /**
     * 重置按钮触发
     */
    nameFormatReset = () => {
        let {
            Playback: {
                TrafficRecord
            },
            getDefaultTrafficRecord
        } = this.props;
        getDefaultTrafficRecord({configData: clone(TrafficRecord)});
    }
    /**
     * 显示命名格式帮助弹框
     */
    showNameFormatHelp = () => {
        this.setState({nameFormatHelpVisible : true});
    }
    /**
     *  关闭命名格式帮助弹框
     */
    onNameFormatHelpOk = () => {
        this.setState({nameFormatHelpVisible : false});
    }
    /**
     *  关闭命名格式帮助弹框
     */
    onNameFormatHelpCancel = () => {
        this.setState({nameFormatHelpVisible : false});
    }
    render () {
        return (
            <div className='tab-content'>
                <StorageComponentFormItem
                    {...this.state}
                    {...this.props}
                    onChangePlainOptions={this.onChangePlainOptions}
                    changeFTPConfig={this.changeFTPConfig}
                    changeNASConfig={this.changeNASConfig}
                    checkSavePath={this.checkSavePath}
                    changeNASProtocolType={this.changeNASProtocolType}
                    handleSubmit={this.handleSubmit}
                    onDefault={this.onDefault}
                    getConfig={this.getConfig}
                    onChangePoint={this.onChangePoint}
                    onChangeDirectoryStructure={this.onChangeDirectoryStructure}
                    onChangeDirectoryLevel1={this.onChangeDirectoryLevel1}
                    onChangeDirectoryLevel2={this.onChangeDirectoryLevel2}
                    onChangeDirectoryLevel3={this.onChangeDirectoryLevel3}
                    onChangeCustom1={this.onChangeCustom1}
                    onChangeCustom2={this.onChangeCustom2}
                    onChangeCustom3={this.onChangeCustom3}
                    onSetPictureNameCustom={this.onSetPictureNameCustom}
                    onSelectChange={this.onSelectChange}
                    onSpaceMarkChange={this.onSpaceMarkChange}
                    onUpAndDown={this.onUpAndDown}
                    onHandleOk={this.onHandleOk}
                    onHandleCancel={this.onHandleCancel}
                    onChangeFTPMode={this.onChangeFTPMode}
                    onFtpTest={this.onFtpTest}
                    onChangeNetWorkStorageMode={this.onChangeNetWorkStorageMode}
                    changeStorageGroup={this.changeStorageGroup}
                    setProtocolEnable={this.setProtocolEnable}
                    handleProtocolEnable={this.handleProtocolEnable}
                    checkServerIP={this.checkServerIP}
                    showFTPTip={this.state.showFTPTip}
                    setFTPEnable={this.setFTPEnable}
                    handleFTPEnable={this.handleFTPEnable}
                    nameFormatPreview={this.nameFormatPreview}
                    nameFormatChange={this.nameFormatChange}
                    nameFormatReset={this.nameFormatReset}
                    showNameFormatHelp={this.showNameFormatHelp}
                    nameFormatHelpVisible={this.state.nameFormatHelpVisible}
                    onNameFormatHelpOk={this.onNameFormatHelpOk}
                    onNameFormatHelpCancel={this.onNameFormatHelpCancel}
                />
            </div>

        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LangConsumer(bindLifecycle(StorageComponent)));

function StorageComponentItem (props) {
    let {
        getConfig,
        onDefault,
        $t,
        handleSubmit,
        Playback,
        Playback: {
            RSP,
            Remote,
            NAS,
            remoteStoreList,
            storagePoint,
            SFTP,
            StorageGroup,
            TrafficRecord
        },
        publicState: {
            getGrid
        },
        emergency,
        storageMode,
        ftpOrSftp,
        storageDir,
        plainOptions,
        checkedPlainOptions,
        onChangePlainOptions,
        storagePointList,
        storagePointVal,
        webCaps,
        directoryStructureList,
        directoryStructureVal,
        onChangeDirectoryStructure,
        directoryLevelVal1,
        directoryLevelVal2,
        directoryLevelVal3,
        directoryLevelCustom1,
        directoryLevelCustom2,
        directoryLevelCustom3,
        pictureNameCustom,
        onChangeDirectoryLevel1,
        onChangeDirectoryLevel2,
        onChangeDirectoryLevel3,
        onChangeCustom1,
        onChangeCustom2,
        onChangeCustom3,
        onSetPictureNameCustom,
        dialogPicName,
        visible,
        aPicNameShow,
        selectedRowKeys,
        onSelectChange,
        onSpaceMarkChange,
        onUpAndDown,
        onHandleOk,
        onHandleCancel,
        directoryLevelList1,
        directoryLevelList2,
        changeFTPConfig,
        changeNASConfig,
        checkSavePath,
        changeNASProtocolType,
        onChangePoint,
        onChangeFTPMode,
        protocolTypeList,
        isShowProtocolTypeSel,
        protocolType,
        supportFtpTest,
        onFtpTest,
        formItemLayout,
        onChangeNetWorkStorageMode,
        NetworkStorageList,
        NetworkStorageValue,
        isShowWarning,
        isShowOverWrite,
        changeStorageGroup,
        showProtocolType,
        setProtocolEnable,
        handleProtocolEnable,
        checkServerIP,
        showFTPTip,
        setFTPEnable,
        handleFTPEnable,
        nameFormatPreview,
        nameFormatChange,
        nameFormatReset,
        showNameFormatHelp,
        nameFormatHelpVisible,
        onNameFormatHelpOk,
        onNameFormatHelpCancel
    } = props;

    let ftpConfig = Playback[ftpOrSftp];
    // let ftpConfig = NAS && NAS.filter((item) => {
    //     return item.Protocol === ftpOrSftp;
    // })[0];
    const multiple = {
        type: 'normal',
        multiple: 1
    };
    const { getFieldDecorator, validateFields, resetFields } = props.form;

    let currentNASConfig = NAS && NAS.filter((item) => {
        return item.Protocol === protocolType;
    })[0];
    let ftpConfigDisabled = checkedPlainOptions.length === 0 || ftpConfig && !ftpConfig.Enable;
    let currentNASConfigDisabled = checkedPlainOptions.length === 0 || currentNASConfig && !currentNASConfig.Enable;
    let commonParameterCharacterArr = ['%01', '%02', '%03', '%04', '%05', '%06', '%07', '%08', '%09', '%10', '%11', '%12',
        '%13', '%14', '%16', '%17', '%18', '%19', '%21', '%22', '%26', '%28', '%36', '%47', '%63', '%66'];
    let commonParameterCharacterTransArr = ['com.DeviceNo', 'com.DeviceIP', 'ivs.CarThroughDirection', 'ivs.Lane',
        'com.Speed', 'com.SpeedLimit', 'ivs.TitleBit63', 'com.CircleNumbers', 'ivs.PlateNo', 'ivs.PlateType',
        'ivs.CarSize', 'com.Carcolor', 'sto.PicGroupTotalNum', 'med.PicCodingNum', 'ivs.PlateColor', 'ivs.LicenseCoord',
        'ivs.BreakRulesCode', 'pfm.SnapAddress', 'com.PresetName', 'com.DeviceName', 'ivs.RoadDirect',
        'com.SerialNo', 'com.Currentset', 'sys.Manufacturer', 'ivs.RoadCode', 'com.PictureType'];
    let timedataParameterCharacterArr = ['%y', '%h', '%M', '%m', '%d', '%s', '', '%S'];
    let timedataParameterCharacterTransArr = ['com.Year', 'com.SingleHour', 'com.Month', 'com.Minute', 'com.DayWeek', 'com.Second', '', 'com.Ms'];
    return (
        ftpConfig ?
            <div>
                <Form onSubmit={handleSubmit.bind(this, validateFields, resetFields)}>
                    <div className={storagePointVal === 'networkStorage' ? 'tab-content tab-content-storage' : 'tab-content'}>
                        <div className='storage'>
                            <div className="content" style={{ padding: '16px 16px 0px 16px' }}>
                                <div className="theme">
                                    <Row className='list-item'>
                                        <Col {...getGrid(multiple).container}>
                                            <ul>
                                                <li className="list-item">
                                                    <Row>
                                                        <Col {...getGrid(multiple).label}>
                                                            <label htmlFor="">{$t('com.EventType')}</label>
                                                        </Col>
                                                        <Col>
                                                            <Checkbox.Group
                                                                value={checkedPlainOptions}
                                                                onChange={onChangePlainOptions}>
                                                                <Checkbox value={'Timing'}>{$t('com.General')}</Checkbox>
                                                                <Checkbox value={'MotionDetect'}>{$t('com.Event')}</Checkbox>
                                                                {
                                                                    webApp.supportAlarm && <Checkbox value={'Alarm'}>{$t('com.Alarm')}</Checkbox>
                                                                }
                                                            </Checkbox.Group>
                                                        </Col>
                                                    </Row>
                                                </li>
                                                <Row className="list-item">
                                                    <Col {...getGrid(multiple).label}>{$t('com.Diskfull')}</Col>
                                                    <Col {...getGrid(multiple).body}>
                                                        <Radio.Group
                                                            value={StorageGroup && StorageGroup[0] && StorageGroup[0].OverWrite}
                                                            onChange={changeStorageGroup}
                                                        >
                                                            <Radio value={true}>{$t('sys.Overwrite')}</Radio>
                                                            <Radio value={false}>{$t('com.Stop')}</Radio>
                                                        </Radio.Group>
                                                    </Col>
                                                </Row>
                                                {isShowOverWrite &&
                                                    <Row className="list-item">
                                                        <Col {...getGrid(multiple).label}></Col>
                                                        <Col {...getGrid(multiple).body}>
                                                            <Alert message={$t('com.StorageStrategy')}
                                                                type="warning" showIcon />

                                                        </Col>
                                                    </Row>
                                                }
                                                <li className="list-item">
                                                    <LabelSelect
                                                        options={storagePointList}
                                                        value={storagePointVal}
                                                        onChange={onChangePoint}
                                                        label={$t('com.StorageMethod')}
                                                        colWidth={getGrid(multiple).colWidth}
                                                        disabled={checkedPlainOptions.length === 0}
                                                        $t={$t}
                                                    />
                                                </li>
                                                {
                                                    storagePointVal === 'networkStorage' &&
                                                    <>
                                                        {isShowWarning &&
                                                            <Row className="list-item">
                                                                <Col {...getGrid(multiple).label}></Col>
                                                                <Col {...getGrid(multiple).body}>
                                                                    <Alert
                                                                        message={$t('com.OnlyOneNetwork')}
                                                                        type="warning"
                                                                        showIcon
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <li className="list-item">
                                                            <LabelSelect
                                                                options={NetworkStorageList}
                                                                value={NetworkStorageValue}
                                                                onChange={onChangeNetWorkStorageMode}
                                                                colWidth={getGrid(multiple).colWidth}
                                                                disabled={checkedPlainOptions.length === 0}
                                                            />
                                                        </li>
                                                    </>
                                                }
                                                {
                                                    storagePointVal === 'networkStorage' && NetworkStorageValue === 'FTP' &&
                                                    <>
                                                        {
                                                            storageMode.length > 1 &&
                                                            <li className="list-item">
                                                                <LabelSelect
                                                                    onChange={onChangeFTPMode}
                                                                    disabled={checkedPlainOptions.length === 0}
                                                                    options={storageMode.map(item => {
                                                                        if (typeof(item.text) === 'function') {
                                                                            return {
                                                                                ...item,
                                                                                text: item.text($t),
                                                                            };
                                                                        }
                                                                        return item;
                                                                    })}
                                                                    value={ftpOrSftp}
                                                                    label={$t('com.Mode')}
                                                                    colWidth={getGrid(multiple).colWidth} />
                                                            </li>
                                                        }
                                                        <li className="list-item">
                                                            <LabelSwitch
                                                                disabled={checkedPlainOptions.length === 0}
                                                                checked={ftpConfig.Enable}
                                                                onChange={(val) => changeFTPConfig('Enable', val)}
                                                                label={$t('com.Enable')}
                                                                colWidth={getGrid(multiple).colWidth}
                                                            />
                                                        </li>
                                                        {/* {
                                                            ftpOrSftp === 'FTP' && ftpConfig.Enable &&
                                                            <Col span={15} className="info" >
                                                                <Alert message={$t('sto.FTPRiskTip')} type="info" />
                                                            </Col>
                                                        } */}
                                                        {
                                                            ftpOrSftp === 'FTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('ServerIP', {
                                                                            rules: [
                                                                                {
                                                                                    validator: checkServerIP
                                                                                }
                                                                            ],
                                                                            initialValue: ftpConfig.Address
                                                                        })(
                                                                            <LabelInput label={$t('net.ServerIP')} className='label-input' onChange={(e) => changeFTPConfig('Address', e.target.value)} disabled={ftpConfigDisabled} maxLength={64} colWidth={getGrid(multiple).colWidth} />
                                                                        )}

                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'SFTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item >
                                                                        {getFieldDecorator('ServerIP2', {
                                                                            rules: [
                                                                                {
                                                                                    validator: checkServerIP
                                                                                }
                                                                            ],
                                                                            initialValue: SFTP.Address
                                                                        })(
                                                                            <LabelInput label={$t('net.ServerIP')} className='label-input' onChange={(e) => changeFTPConfig('Address', e.target.value)} disabled={ftpConfigDisabled} maxLength={64} colWidth={getGrid(multiple).colWidth} />
                                                                        )}

                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'FTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign' >
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Port', {
                                                                            // rules: [
                                                                            //     {
                                                                            //         required: true,
                                                                            //         message: $t('com.LocalNoTip')
                                                                            //     },
                                                                            //     {
                                                                            //         pattern: /^([0-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3}|[1-5][0-9]{4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/i,
                                                                            //         message: $t('com.LocalNoTip')
                                                                            //     }
                                                                            // ],
                                                                            initialValue: ftpConfig.Port
                                                                        })(
                                                                            <LabelInput label={$t('net.Port')} type='number' className='label-input' min={0} max={65535} maxLength={5} onChange={(value) => changeFTPConfig('Port', value - 0)} disabled={ftpConfigDisabled} colWidth={getGrid(multiple).colWidth} behind={'(0~65535)'} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'SFTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign' >
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Port2', {
                                                                            // rules: [
                                                                            //     {
                                                                            //         required: true,
                                                                            //         message: $t('com.LocalNoTip')
                                                                            //     },
                                                                            //     {
                                                                            //         pattern: /^([0-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3}|[1-5][0-9]{4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/i,
                                                                            //         message: $t('com.LocalNoTip')
                                                                            //     }
                                                                            // ],
                                                                            initialValue: SFTP.Port
                                                                        })(
                                                                            <LabelInput label={$t('net.Port')} type='number' className='label-input' min={0} max={65535} maxLength={5} onChange={(value) => changeFTPConfig('Port', value - 0)} disabled={ftpConfigDisabled} colWidth={getGrid(multiple).colWidth} behind={'(0~65535)'} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'FTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('UserName', {
                                                                            rules: [{
                                                                                pattern: /^[^'"’“]+$/,
                                                                                message: $t('com.NoInputMarksTip')
                                                                            }],
                                                                            initialValue: ftpConfig.UserName,
                                                                            normalize: (value, prevValue) => {
                                                                                if (value && lengthB(value) > 32) {
                                                                                    return prevValue;
                                                                                } else {
                                                                                    return value;
                                                                                }
                                                                            }
                                                                        })(
                                                                            <LabelInput label={$t('com.Username')} className='label-input' onChange={(e) => changeFTPConfig('UserName', e.target.value)} disabled={ftpConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'SFTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('UserName2', {
                                                                            rules: [
                                                                                {
                                                                                    pattern: /^[^'"’“]+$/,
                                                                                    message: $t('com.NoInputMarksTip')
                                                                                }
                                                                            ],
                                                                            initialValue: SFTP.UserName,
                                                                            normalize: (value, prevValue) => {
                                                                                if (value && lengthB(value) > 32) {
                                                                                    return prevValue;
                                                                                } else {
                                                                                    return value;
                                                                                }
                                                                            }
                                                                        })(
                                                                            <LabelInput label={$t('com.Username')} className='label-input' onChange={(e) => changeFTPConfig('UserName', e.target.value)} disabled={ftpConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'FTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item >
                                                                        {getFieldDecorator('Password', {
                                                                            rules: [{
                                                                                pattern: /^[^'"’“]+$/,
                                                                                message: $t('com.NoInputMarksTip')
                                                                            }],
                                                                            initialValue: ftpConfig.Password || '******'
                                                                        })(
                                                                            <LabelInput label={$t('com.Password')} className='label-input' onChange={(val) => {changeFTPConfig('Password', val);}} disabled={ftpConfigDisabled} maxLength={32} type='password' colWidth={getGrid(multiple).colWidth} pswMaskTip = {$t('com.ReservedMaskPwdTip')}/>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'SFTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Password2', {
                                                                            rules: [{
                                                                                pattern: /^[^'"’“]+$/,
                                                                                message: $t('com.NoInputMarksTip')
                                                                            }],
                                                                            initialValue: SFTP.Password || '******'
                                                                        })(
                                                                            <LabelInput label={$t('com.Password')} className='label-input' onChange={(val) => {changeFTPConfig('Password', val);}} type='password' disabled={ftpConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} pswMaskTip = {$t('com.ReservedMaskPwdTip')}/>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'FTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Directory', {
                                                                            rules: [{ validator: checkSavePath }],
                                                                            initialValue: ftpConfig.Directory
                                                                        })(
                                                                            <LabelInput label={$t('com.Savepath')} className='label-input' onChange={(e) => changeFTPConfig('Directory', e.target.value)} disabled={ftpConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            ftpOrSftp === 'SFTP' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Directory2', {
                                                                            rules: [{ validator: checkSavePath }],
                                                                            initialValue: SFTP.Directory
                                                                        })(
                                                                            <LabelInput label={$t('com.Savepath')} className='label-input' onChange={(e) => changeFTPConfig('Directory', e.target.value)} disabled={ftpConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            webCaps.is_show_FtpPathSimplified &&
                                                            <>
                                                                <li className="list-item">
                                                                    <LabelSelect
                                                                        options={directoryStructureList}
                                                                        value={directoryStructureVal}
                                                                        onChange={onChangeDirectoryStructure}
                                                                        label={$t('sto.DirectoryStructure')}
                                                                        colWidth={getGrid(multiple).colWidth}
                                                                        $t={$t}
                                                                    />
                                                                </li>
                                                                <li className="list-item storage-lineheight0">
                                                                    <LabelSelect
                                                                        options={directoryLevelList1}
                                                                        value={directoryLevelVal1}
                                                                        onChange={onChangeDirectoryLevel1}
                                                                        behind={directoryLevelVal1 === '' ? <Col {...getGrid(multiple).container}><Form.Item>{getFieldDecorator('directoryLevelCustom1b2', {
                                                                            initialValue: directoryLevelCustom1,
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: $t('com.Necessary'),
                                                                                },
                                                                                {
                                                                                    pattern: /^[a-zA-Z0-9_-]*$/,
                                                                                    message: $t('com.BadstringServerNameTip'),
                                                                                }
                                                                            ],
                                                                        })(
                                                                            <Input
                                                                                onChange={onChangeCustom1}
                                                                                maxLength={64}
                                                                                style={{ marginLeft: '10px' }}
                                                                            />
                                                                        )}
                                                                        </Form.Item></Col> : ''}
                                                                        label={$t('sto.DirectoryLelvel1')}
                                                                        colWidth={getGrid(multiple).colWidth}
                                                                        $t={$t}
                                                                    />
                                                                </li>
                                                                <li className="list-item storage-lineheight0">
                                                                    {
                                                                        directoryStructureVal > 1 &&
                                                                        <>
                                                                            <LabelSelect
                                                                                options={directoryLevelList2}
                                                                                value={directoryLevelVal2}
                                                                                onChange={onChangeDirectoryLevel2}
                                                                                behind={directoryLevelVal2 === '' ? <Col {...getGrid(multiple).container}><Form.Item>{getFieldDecorator('directoryLevelCustom2b2', {
                                                                                    initialValue: directoryLevelCustom2,
                                                                                    rules: [
                                                                                        {
                                                                                            required: true,
                                                                                            message: $t('com.Necessary'),
                                                                                        },
                                                                                        {
                                                                                            pattern: /^[a-zA-Z0-9_-]*$/,
                                                                                            message: $t('com.BadstringServerNameTip'),
                                                                                        }
                                                                                    ],
                                                                                })(
                                                                                    <Input
                                                                                        onChange={onChangeCustom2}
                                                                                        maxLength={64}
                                                                                        style={{ marginLeft: '10px' }}
                                                                                    />
                                                                                )}
                                                                                </Form.Item></Col> : ''}
                                                                                label={$t('sto.DirectoryLelvel2')}
                                                                                colWidth={getGrid(multiple).colWidth}
                                                                                $t={$t}
                                                                            />
                                                                        </>
                                                                    }
                                                                </li>
                                                                <li className="list-item storage-lineheight0">
                                                                    {
                                                                        directoryStructureVal > 2 &&
                                                                        <>
                                                                            <LabelSelect
                                                                                options={directoryLevelList2}
                                                                                value={directoryLevelVal3}
                                                                                onChange={onChangeDirectoryLevel3}
                                                                                behind={directoryLevelVal3 === '' ? <Col {...getGrid(multiple).container}><Form.Item>{getFieldDecorator('directoryLevelCustom3b2', {
                                                                                    initialValue: directoryLevelCustom3,
                                                                                    rules: [
                                                                                        {
                                                                                            required: true,
                                                                                            message: $t('com.Necessary'),
                                                                                        },
                                                                                        {
                                                                                            pattern: /^[a-zA-Z0-9_-]*$/,
                                                                                            message: $t('com.BadstringServerNameTip'),
                                                                                        }
                                                                                    ],
                                                                                })(
                                                                                    <Input
                                                                                        onChange={onChangeCustom3}
                                                                                        maxLength={64}
                                                                                        style={{ marginLeft: '10px' }}
                                                                                    />
                                                                                )}
                                                                                </Form.Item></Col> : ''}
                                                                                label={$t('sto.DirectoryLelvel3')}
                                                                                colWidth={getGrid(multiple).colWidth}
                                                                                $t={$t}
                                                                            />
                                                                        </>
                                                                    }
                                                                </li>
                                                                {/* <li>
                                                                <LabelInput
                                                                    label={$t('sto.PictureNameCustom')}
                                                                    className='label-input'
                                                                    value={pictureNameCustom}
                                                                    behind={<Button onClick={onSetPictureNameCustom} className='fn-margin-right10'>{$t('com.Setting')}</Button>}
                                                                    disabled={true}
                                                                    colWidth={getGrid(multiple).colWidth}
                                                                />
                                                            </li> */}
                                                            </>
                                                        }
                                                        {
                                                            webApp.IsLocalStore && <li className="list-item">
                                                                <LabelSwitch disabled={ftpConfigDisabled} checked={emergency} onChange={(val) => changeFTPConfig('emergency', val)} label={$t('sto.ExigencyStorageLocal')} colWidth={getGrid(multiple).colWidth} />
                                                            </li>
                                                        }

                                                        {
                                                            supportFtpTest &&
                                                            <div className="operation fn-width200 fn-margin-top10 btn-margin">
                                                                <Row>
                                                                    <Col {...getGrid(multiple).label}></Col>
                                                                    <Col {...getGrid(multiple).body}>
                                                                        <Button onClick={onFtpTest} className='fn-margin-right10'>{$t('com.Test')}</Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }
                                                        {
                                                            webCaps.is_show_FtpPathSimplified &&
                                                            <>
                                                                <Modal
                                                                    title={$t('sto.PictureNameSetting')}
                                                                    visible={visible}
                                                                    width="600px"
                                                                    okText={$t('com.Ok')}
                                                                    cancelText={$t('com.Cancel')}
                                                                    onOk={onHandleOk}
                                                                    onCancel={onHandleCancel}
                                                                    getContainer={() => { return document.querySelector('.storage'); }}
                                                                    cancelButtonProps={{ style: { float: 'right', marginLeft: '10px' } }}
                                                                >
                                                                    <FTPStorageNameTable
                                                                        dataSource={aPicNameShow}
                                                                        selectedRowKeys={selectedRowKeys}
                                                                        onSelectChange={onSelectChange}
                                                                        onSpaceMarkChange={onSpaceMarkChange}
                                                                        onUpAndDown={onUpAndDown}
                                                                    />
                                                                    <div className="fn-margin-top10 fn-margin-left10" style={{ wordBreak: 'break-all' }}>{dialogPicName}</div>
                                                                </Modal>
                                                            </>
                                                        }
                                                    </>
                                                }

                                                {
                                                    storagePointVal === 'networkStorage' && NetworkStorageValue === 'NAS' && NAS &&
                                                    <>
                                                        {
                                                            isShowProtocolTypeSel &&
                                                            <>
                                                                <li className="list-item">
                                                                    <LabelSelect disabled={checkedPlainOptions.length === 0} value={protocolType} options={protocolTypeList} onChange={changeNASProtocolType} label={$t('com.Protocoltype')} colWidth={getGrid(multiple).colWidth} />
                                                                </li>
                                                            </>
                                                        }
                                                        <li className="list-item">
                                                            <LabelSwitch disabled={checkedPlainOptions.length === 0} checked={currentNASConfig.Enable} onChange={(val) => changeNASConfig('Enable', val)} label={$t('com.Enable')} colWidth={getGrid(multiple).colWidth} />
                                                        </li>
                                                        {
                                                            protocolType === 'NFS' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('ServerIP3', {
                                                                            rules: [
                                                                                {
                                                                                    validator: checkServerIP
                                                                                }
                                                                            ],
                                                                            initialValue: currentNASConfig.Address
                                                                        })(
                                                                            <LabelInput label={$t('net.ServerIP')} className='label-input' onChange={(e) => changeNASConfig('Address', e.target.value)} disabled={currentNASConfigDisabled} maxLength={64} colWidth={getGrid(multiple).colWidth} />
                                                                        )}

                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            protocolType === 'NFS' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Directory3', {
                                                                            rules: [{ validator: checkSavePath }],
                                                                            initialValue: currentNASConfig.Directory
                                                                        })(
                                                                            <LabelInput label={$t('com.Savepath')} className='label-input' onChange={(e) => changeNASConfig('Directory', e.target.value)} disabled={currentNASConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            protocolType === 'SMB' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('ServerIP4', {
                                                                            rules: [
                                                                                {
                                                                                    validator: checkServerIP
                                                                                }
                                                                            ],
                                                                            initialValue: currentNASConfig.Address
                                                                        })(
                                                                            <LabelInput label={$t('net.ServerIP')} className='label-input' onChange={(e) => changeNASConfig('Address', e.target.value)} disabled={currentNASConfigDisabled} maxLength={64} colWidth={getGrid(multiple).colWidth} />
                                                                        )}

                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            protocolType === 'SMB' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('Directory4', {
                                                                            rules: [{ validator: checkSavePath }],
                                                                            initialValue: currentNASConfig.Directory
                                                                        })(
                                                                            <LabelInput label={$t('com.Savepath')} className='label-input' onChange={(e) => changeNASConfig('Directory', e.target.value)} disabled={currentNASConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            protocolType === 'SMB' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item >
                                                                        {getFieldDecorator('UserName3', {
                                                                            rules: [{
                                                                                pattern: /^[^'"’“]+$/,
                                                                                message: $t('com.NoInputMarksTip')
                                                                            }],
                                                                            initialValue: currentNASConfig.UserName,
                                                                            normalize: (value, prevValue) => {
                                                                                if (value && lengthB(value) > 32) {
                                                                                    return prevValue;
                                                                                } else {
                                                                                    return value;
                                                                                }
                                                                            }
                                                                        })(
                                                                            <LabelInput label={$t('com.Username')} className='label-input' onChange={(e) => changeNASConfig('UserName', e.target.value)} disabled={currentNASConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            protocolType === 'SMB' &&
                                                            <Row>
                                                                <Col span={24} className='labelCol textAlign'>
                                                                    <Form.Item >
                                                                        {getFieldDecorator('Password', {
                                                                            rules: [{
                                                                                pattern: /^[^'"’“]+$/,
                                                                                message: $t('com.NoInputMarksTip')
                                                                            }],
                                                                            initialValue: currentNASConfig.Password || '******'
                                                                        })(
                                                                            <LabelInput label={$t('com.Password')} className='label-input' onChange={(val) => {changeNASConfig('Password', val);}} type='password' disabled={currentNASConfigDisabled} maxLength={32} colWidth={getGrid(multiple).colWidth} pswMaskTip = {$t('com.ReservedMaskPwdTip')}/>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {webApp.GongAnDetect &&
                                                            <div>
                                                                <li className="list-item">
                                                                    <LabelSwitch label={$t('net.StartSMB/CIFS')} colWidth={getGrid(multiple).colWidth} />
                                                                </li>
                                                                <Row>
                                                                    <Col span={24} className='labelCol textAlign'>
                                                                        <Form.Item >
                                                                            <LabelInput label={$t('net.ServerIP')} className='label-input' colWidth={getGrid(multiple).colWidth}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={24} className='labelCol textAlign'>
                                                                        <Form.Item >
                                                                            <LabelInput label={$t('com.Username')} className='label-input' colWidth={getGrid(multiple).colWidth}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={24} className='labelCol textAlign'>
                                                                        <Form.Item >
                                                                            <LabelInput label={$t('com.Password')} className='label-input' type='password' maxLength={32} colWidth={getGrid(multiple).colWidth}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={24} className='labelCol textAlign'>
                                                                        <Form.Item >
                                                                            <LabelInput label={$t('com.Savepath')} className='label-input' colWidth={getGrid(multiple).colWidth}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {
                                                    webApp.DeviceType.indexOf('SD') !== -1 && !!webCaps.is_show_newIntelli && storagePointVal === 'networkStorage' && NetworkStorageValue === 'FTP' &&
                                                    <>
                                                        {/* 录像及存储路径命名 */}
                                                        <Row className="list-item fn-mart10">
                                                            <Col {...getGrid(multiple).label}>{$t('sto.RecStorageVideoName')}</Col>
                                                            <Col {...getGrid(multiple).body}>
                                                                <TextArea maxLength= {300} className = 'textAreaHeight' value = {TrafficRecord && TrafficRecord[0] && TrafficRecord[0].VideoNamingFormat ? TrafficRecord[0].VideoNamingFormat.Format : ''} onChange = {(e) => nameFormatChange(e, 'mergeOSD')}/>
                                                            </Col>
                                                            <Col {...getGrid(multiple).behind} className = 'fn-marl10'>
                                                                <Button onClick = {() => nameFormatReset()}>{$t('com.Reset')}</Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="list-item fn-mart10">
                                                            <Col {...getGrid(multiple).label}></Col>
                                                            <Col {...getGrid(multiple).body}>
                                                                <TextArea className = 'textAreaHeight' disabled = {true} value = {nameFormatPreview()}/>
                                                            </Col>
                                                            <Col {...getGrid(multiple).behind} className = 'fn-marl10'>
                                                                <Button onClick={() => showNameFormatHelp()}>{$t('com.Help')}</Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                                <Modal
                                                    title={$t('ivs.NamingFormat')}
                                                    visible={nameFormatHelpVisible}
                                                    width="700px"
                                                    okText={$t('com.Ok')}
                                                    cancelText={$t('com.Cancel')}
                                                    onOk={onNameFormatHelpOk}
                                                    onCancel={onNameFormatHelpCancel}
                                                    getContainer={() => { return document.querySelector('.storage'); }}
                                                    cancelButtonProps={{style:{display:'none'}}}
                                                >
                                                    <h3>{$t('com.Params')}</h3>
                                                    <Row>
                                                        {commonParameterCharacterArr.map((item, index) => {
                                                            return (
                                                                <Col span={12} key = {index}>
                                                                    <Col {...getGrid(multiple).label}>{item}</Col>
                                                                    <Col {...getGrid(multiple).body}>{commonParameterCharacterTransArr[index] !== 'pfm.SnapAddress' ? $t(commonParameterCharacterTransArr[index]) : $t(commonParameterCharacterTransArr[index]) + '(' + $t('com.ChannelTitle') + ')'}</Col>
                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                    <br/>
                                                    <br/>
                                                    <h3>{$t('com.DataParams')}</h3>
                                                    <Row>
                                                        {timedataParameterCharacterArr.map((item, index) => {
                                                            return (
                                                                <Col span={12} key = {index}>
                                                                    <Col {...getGrid(multiple).label}>{item}</Col>
                                                                    <Col {...getGrid(multiple).body}>{$t(timedataParameterCharacterTransArr[index])}</Col>
                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                    <br/>
                                                    <br/>
                                                    <Row style={{color: 'red'}}>{$t('com.TpFtpTip1')}</Row>
                                                    <Row style={{color: 'red'}}>{$t('com.TpFtpTip2')}</Row>
                                                    <Row style={{color: 'red'}}>{$t('com.TpFtpTip3')}</Row>
                                                    <Row style={{color: 'red'}}>{$t('com.TpFtpTip4')}</Row>
                                                    <Row style={{color: 'red'}}>{$t('com.NotHaveChineseTip5')}</Row>
                                                </Modal>
                                            </ul>
                                            <Row style={{ marginTop: '10px' }}>
                                                <Col {...getGrid(multiple).label}>
                                                </Col>
                                                <Col>
                                                    <Button type="primary" htmlType="submit" className='fn-margin-right10 _submitButton'>{$t('com.Apply')}</Button>
                                                    <Button onClick={getConfig.bind(this, { flag: true, resetFields })} className='fn-margin-right10 _refreshButton'>{$t('com.Refresh')}</Button>
                                                    <Button className='_defaultButton' onClick={onDefault.bind(this, { resetFields })}>{$t('com.Comdefault')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </div>
                    </div>
                </Form>
                <Modal
                    title={$t('com.Prompt')}
                    centered={true}
                    visible={showProtocolType}
                    onOk={setProtocolEnable}
                    onCancel={handleProtocolEnable}>
                    <p>{ protocolType === 'NFS' ? $t('sto.NFSRiskTip') : $t('sto.SMBRiskTip') }</p>
                </Modal>
                <Modal
                    title={$t('com.Prompt')}
                    centered={true}
                    visible={showFTPTip}
                    onOk={setFTPEnable}
                    onCancel={handleFTPEnable}>
                    <p>{ $t('sto.FTPRiskTip') }</p>
                </Modal>
            </div>
            : <></>
    );
}

StorageComponentItem.propTypes = {
};
const StorageComponentFormItem = Form.create()(StorageComponentItem);